import logo from "./logo.svg";
import logoText from "./fit-counter.svg";
import styles from "./App.module.scss";

function App() {
  return (
    <div className={styles.main}>
      <div className={styles.claim}>
        <img src={logoText} className={styles.logoText} alt="logo" />
        <p>
          Keep track of the meals and the calories you take throughout the day.
          FitCounter will help you achieve your fitness goal once and for all!
        </p>
      </div>
      <img src={logo} className={styles.logo} alt="logo" />
    </div>
  );
}

export default App;
